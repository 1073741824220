import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../store";
import api from "services/ApiService";
import {ProgressState, NumMapOf, UserLineup, UserLineupDetail} from "./types";

export interface lineupState {
  lineupGetStatus: ProgressState
  lineupsGetStatus: ProgressState
  calculateStatus: ProgressState
  lineups: NumMapOf<UserLineup>
  lineupDetails?: UserLineupDetail
}

const initialState: lineupState = {
  lineupGetStatus: "idle",
  lineupsGetStatus: "idle",
  calculateStatus: "idle",
  lineups: {},
}

const reducer = createSlice({
  name: 'lineup',
  initialState,
  reducers: {
    getLineupsInit(state) {
      state.lineupsGetStatus = "loading"
    },
    getLineupsSuccess(state, action: PayloadAction<UserLineup[]>) {
      const lineups: NumMapOf<UserLineup> = {};
      action.payload.forEach(p => lineups[p.id] = p)
      state.lineups = lineups;
      state.lineupsGetStatus = "succeeded"
    },
    getLineupsError(state) {
      state.lineupsGetStatus = "failed"
    },
    getSingleLineupInit(state) {
      state.lineupGetStatus = "loading"
    },
    getSingleLineupSuccess(state, action: PayloadAction<UserLineupDetail>) {
      state.lineupDetails = action.payload;
      state.lineupGetStatus = "succeeded"
    },
    getSingleLineupError(state) {
      state.lineupGetStatus = "failed"
    },
    calculateInit(state) {
      state.calculateStatus = "loading";
    },
    calculateSuccess(state) {
      state.calculateStatus = "succeeded";
    },
    calculateError(state) {
      state.calculateStatus = "failed";
    }
  }
});

const {
  getLineupsInit,
  getLineupsSuccess,
  getLineupsError,
  getSingleLineupError,
  getSingleLineupInit,
  getSingleLineupSuccess,
  calculateInit,
  calculateError,
  calculateSuccess,
} = reducer.actions;

export const getLineupAction = (id: number) => (dispatch: AppDispatch) => {
  dispatch(getSingleLineupInit());
  api.get(`/admin/lineups/${id}`)
    .then(res => {
      dispatch(getSingleLineupSuccess(res))
    })
    .catch(() => dispatch(getSingleLineupError()))
}

export const getLineupsAction = (periodId: number) => (dispatch: AppDispatch) => {
  dispatch(getLineupsInit());
  api.get(`/admin/lineups?periodId=${periodId}`)
    .then(res => {
      dispatch(getLineupsSuccess(res))
    })
    .catch(() => dispatch(getLineupsError()))
}

export const calculateScoresAction = (periodId: number) => (dispatch: AppDispatch) => {
  dispatch(calculateInit());
  api.post(`/admin/lineups/calculate?periodId=${periodId}`)
    .then(res => {
      dispatch(calculateSuccess());
      dispatch(getLineupsAction(periodId));
    })
    .catch(() => dispatch(calculateError()))
}

export const calculateLineupScoreAction = (lineupId: number, periodId: number) => (dispatch: AppDispatch) => {
  dispatch(calculateInit());
  api.post(`/admin/lineups/${lineupId}/calculate?periodId=${periodId}`)
    .then(res => {
      dispatch(calculateSuccess());
      dispatch(getLineupAction(lineupId))
    })
    .catch(e => {
      console.log(e);
      dispatch(calculateError());
    })
}

export default reducer;