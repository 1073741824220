import { combineReducers } from 'redux'
import message from './message';
import auth from './auth';
import player from './player';
import lineup from './lineup';
import period from './period';

export default combineReducers({
  message: message.reducer,
  auth: auth.reducer,
  player: player.reducer,
  lineup: lineup.reducer,
  period: period.reducer,
});