const KEY = 'token';

export const getToken = () => localStorage.getItem(KEY) ?? undefined;

export const setToken = (token: string) => localStorage.setItem(KEY, token);

export const removeToken = () => localStorage.removeItem(KEY);

export default {
  getToken, setToken, removeToken
}