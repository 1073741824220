import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../store";
import session from "services/SessionService";
import api from "services/ApiService";
import {ProgressState} from "./types";

type AuthCheckStatus = "notstarted" | "inprogress" | "done"

export interface AuthState {
  loginStatus: ProgressState,
  check: AuthCheckStatus,
  isAuth: boolean
  role: string
}

const initialState: AuthState = {
  loginStatus: 'idle',
  check: "notstarted",
  isAuth: false,
  role: "NONE"
}

interface LoginResponse {
  token: string
  role: string
}

const reducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<string>) {
      state.check = "done"
      state.isAuth = true;
      state.role = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.check = "done"
      state.isAuth = true;
      session.setToken(action.payload);
    },
    removeAuth(state) {
      state.check = "done"
      state.isAuth = false;
      session.removeToken();
    },
    authCheckStarted(state) {
      state.check = "inprogress"
    },
    loginStart(state) {
      state.loginStatus = 'loading'
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.loginStatus = 'succeeded'
      state.isAuth = true;
      state.role = action.payload.role;
      session.setToken(action.payload.token);
    },
    loginFailure(state) {
      state.loginStatus = 'failed'
    }
  }
});

const {
  authCheckStarted,
  loginStart,
  loginSuccess,
  loginFailure
} = reducer.actions;

export const checkAuthAction = () => (dispatch: AppDispatch) => {
  interface AuthCheckResponse {
    id: number
    role: string
  }
  dispatch(authCheckStarted());
  api.get('/auth')
    .then((res: AuthCheckResponse) => dispatch(setAuth(res.role)))
    .catch(() => dispatch(removeAuth()));
};

export const loginAction = (email: string, password: string) => (dispatch: AppDispatch) => {

  dispatch(loginStart())
  api.post('/auth/login/email', {email, password})
    .then((res: LoginResponse) => dispatch(loginSuccess(res)))
    .catch(() => dispatch(loginFailure()))
}

export const {
  setAuth,
  removeAuth
} = reducer.actions

export default reducer;