import React, {useEffect} from "react";
import {useParams, Link} from "react-router-dom";
import {Table, Button, Tag, Space} from 'antd';
import {calculateLineupScoreAction, getLineupAction} from "../../reducers/lineup";
import {useAppDispatch, useSelector} from "../../store";
import Loading from "../../components/Loading";
import {PositionMap, UserLineupPlayer} from "../../reducers/types";

const {Column} = Table;

const LineupDetailsPage = () => {
  const {id} = useParams<{ id: string }>();
  const idInt = parseInt(id);
  const dispatch = useAppDispatch();
  const getLineupState = useSelector(s => s.lineup.lineupGetStatus);
  const lineup = useSelector(s => s.lineup.lineupDetails);

  useEffect(() => {
    dispatch(getLineupAction(idInt))
  }, []);

  if (!lineup || getLineupState === "loading") return <Loading />;

  const renderPos = (p: UserLineupPlayer) => {
    const prefix = p.lineupGroup === 'PRIMARY' ? '' : 'Yedek ';
    return `${prefix}${PositionMap[p.lineupPosition]}`;
  }

  const renderStats = (p: UserLineupPlayer) => {
    if (!p.stats) return null;

    const keys = Object.keys(p.stats);
    return keys.map(key => {
      if (p.stats![key] > 0) return <Tag key={key}>{key}:{p.stats![key]}</Tag>
      return '';
    })
  }
  const recalculate = () => {
    dispatch(calculateLineupScoreAction(idInt, lineup.periodId))
  }

  return <Space direction="vertical" style={{width: "100%"}}>
    <Table dataSource={lineup.players} pagination={false} size="small" rowKey={r => r.player.id}>
      {/*<Column key="id" render={renderPos} />*/}
      <Column key="name" title="Oyuncu" render={p => <Link to={`/players/${p.player.id}`}>{p.player.name}</Link>} />
      <Column key="value" title="Değeri" render={p => p.value} />
      <Column key="value" title="Puanı" align="right" render={p => p.score > 0 ? <Tag color="orange">{p.score}</Tag> : ''} />
      <Column key="value" render={renderStats} />
    </Table>
    <Button onClick={recalculate}>Puanı tekrar hesapla</Button>
  </Space>
}

export default LineupDetailsPage;