import {Route, Switch} from "react-router-dom";
import LoginPage from "../Login";
import LogoutPage from "../Logout";
import PlayersListPage from "../Players/list";
import PlayerDetailsPage from "../Players/detail";
import LineupListPage from "../Lineups/list";
import LineupDetailsPage from "../Lineups/detail";
import PeriodsListPage from "../Periods/list";
import PeriodDetailsPage from "../Periods/detail";
import HomePage from "../Home";
import React from "react";
import {Redirect} from "react-router";

interface RoutesProps {
  isAdmin: boolean
  isAuth: boolean
}
const Routes = ({isAdmin, isAuth}: RoutesProps) => {

  const PrivateRoute = ({authCheck, component, ...rest}: any) => {
    const routeComponent = (props: any) => (
      authCheck
        ? React.createElement(component, props)
        : <Redirect to={{pathname: '/auth/login'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
  };

  const AuthRoute = ({...props}: any) => <PrivateRoute authCheck={isAuth} {...props} />
  const AdminRoute = ({...props}: any) => <PrivateRoute authCheck={isAdmin} {...props} />

  return <Switch>
    <Route path="/auth/login">
      <LoginPage/>
    </Route>

    <AuthRoute path="/auth/logout" component={LogoutPage} />
    <AdminRoute path="/players/:id" exact component={PlayerDetailsPage} />
    <AdminRoute path="/players" exact component={PlayersListPage} />
    <AdminRoute path="/periods/:id" exact component={PeriodDetailsPage} />
    <AdminRoute path="/periods" exact component={PeriodsListPage} />
    <AdminRoute path="/lineups/period/:periodId" exact component={LineupListPage} />
    <AdminRoute path="/lineups/:id" exact component={LineupDetailsPage} />

    <AuthRoute path="/" component={HomePage} />
  </Switch>
}

export default Routes;