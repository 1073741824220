import React, {useEffect} from "react";
import {Progress, Table, Tag, Button} from "antd";
import {useParams, Link} from "react-router-dom";
import {useAppDispatch, useSelector} from "../../store";
import Loading from "../../components/Loading";
import {getLineupsAction, calculateScoresAction} from "../../reducers/lineup";
import {getPeriodAction} from "../../reducers/period";

const {Column} = Table;

const LineupListPage = () => {
  const {periodId} = useParams();
  const period = useSelector(state => state.period.periods[periodId]);
  const lineupsGetStatus = useSelector(s => s.lineup.lineupsGetStatus);
  const lineups = useSelector(s => s.lineup.lineups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPeriodAction(periodId))
    dispatch(getLineupsAction(periodId));
  }, []);

  if (!period || lineupsGetStatus !== "succeeded") return <Loading/>;

  const recalculate = () => {
    dispatch(calculateScoresAction(periodId))
  }

  const renderProgress = (row: any) => {
    return <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Progress percent={Math.round(row.players.statsReceived * 100 / row.players.total)} size="small"
                showInfo={false}/>
      <span style={{fontSize: 12}}>{row.players.statsReceived}/{row.players.total}</span>
    </div>
  }

  return <div>
    <Table dataSource={Object.values(lineups)} pagination={{pageSize: 30}} rowKey={r => r.id} size="small">
      <Column title="" key="id" render={r => <Link to={`/lineups/${r.id}`}>{r.id}</Link>}/>
      <Column title="Oyuncu" key="user" dataIndex="user"/>
      <Column title="Bütçe" key="budget" dataIndex="budget" align="right"/>
      <Column title="Toplam Değer" key="totalValue" dataIndex="totalValue" align="right"/>
      <Column title="Skor" key="totalValue" dataIndex="score" align="right"
              render={score => <Tag color="orange">{score}</Tag>}/>
      <Column title="Tamamlanma" key="progress" align="right" render={renderProgress}/>
    </Table>

    <Button type="primary" onClick={recalculate}>Puanları Tekrar Hesapla</Button>
  </div>
}

export default LineupListPage;