import React, {useEffect} from "react";
import {Button, DatePicker, Form, Input} from "antd";
import {useParams} from "react-router-dom";
import {useAppDispatch, useSelector} from "../../store";
import {getPeriodAction, savePeriodAction} from "../../reducers/period";
import moment from 'moment';
import Loading from "../../components/Loading";

const { RangePicker } = DatePicker;

const PeriodDetail = () => {
  const {id} = useParams<{ id: string }>();
  const idInt = parseInt(id);
  const period = useSelector(s => s.period.periods[idInt])
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPeriodAction(idInt));
  }, []);

  if (!period) return <Loading />;

  const handleSave = (values: any) =>{
    const data = {
      name: values.name,
      startDate: values.dates[0].format('YYYY-MM-DD'),
      endDate: values.dates[1].format('YYYY-MM-DD'),
    }
    dispatch(savePeriodAction({id: idInt, data}))
  }

  const initialValues = {
    name: period.name,
    dates: [moment(period.startsAt), moment(period.endsAt)]
  };

  return <div>
    <Form layout="vertical" initialValues={initialValues} onFinish={handleSave}>
      <Form.Item name="name" label="İsim">
        <Input />
      </Form.Item>
      <Form.Item name="dates" label="Zaman Aralığı">
        <RangePicker />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Kaydet</Button>
      </Form.Item>
    </Form>
  </div>
}

export default PeriodDetail;