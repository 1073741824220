import React, {useEffect} from 'react';
import {useAppDispatch, useSelector} from "store";
import {message} from "antd";
// import errors from 'config/errors.json';
import {MessageState, clearMessages} from "../../reducers/message";

export const ErrorDisplayer = () => {

  const messages: MessageState = useSelector(state => state.message)
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (messages.error) {
      message.error("api_error_" + messages.error);
      dispatch(clearMessages())
    } else if (messages.success) {
      message.success(messages.success)
      dispatch(clearMessages())
    }
  }, [messages.error, messages.success])

  return null;
}

export default ErrorDisplayer;