import {DateTime} from "luxon";

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const strToDate = (d: string) => DateTime.fromISO(d);

export const formatDay = (d: string) => strToDate(d).toISODate();

export const datesContainNow = (sd: DateTime, ed: DateTime) => {
  const now = DateTime.now();
  return now > sd && now < ed;
}

export const sortArray = (arr: any[], ind: string) => {
  function compare(a: any, b: any) {
    if (a[ind] < b[ind]) {
      return -1;
    }
    if (a[ind] > b[ind]) {
      return 1;
    }
    return 0;
  }

  return [...arr].sort(compare);
}