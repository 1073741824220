import React, {useEffect} from "react";
import {Button, DatePicker, Form, Input, Switch, Table} from "antd";
import {Link} from "react-router-dom";
import {useAppDispatch, useSelector} from "../../store";
import {getPeriodsAction, savePeriodAction} from "../../reducers/period";
import Loading from "../../components/Loading";
import {formatDay} from "../../services/Utils";
import {Period} from "../../reducers/types";

const {Column} = Table;

const PeriodList = () => {
  const periodsGetStatus = useSelector(s => s.period.periodsGetStatus);
  const periods = useSelector(s => s.period.periods);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPeriodsAction());
  }, []);

  if (periodsGetStatus !== "succeeded") return <Loading/>;

  const handleGameStatus = (id: number, checked: boolean) => {
    dispatch(savePeriodAction({id, data: {gameAllowed: checked}}));
  }

  const handleStatus = (id: number, checked: boolean) => {
    dispatch(savePeriodAction({id, data: {isActive: checked}}));
    dispatch(getPeriodsAction());
  }

  const handleCreatePeriod = (values: any) => {
    const startDate = values.startDate.format('YYYY-MM-DD');
    const endDate = values.endDate.format('YYYY-MM-DD');
    dispatch(savePeriodAction({
      data: {
        name: values.name,
        startDate,
        endDate
      }
    }));
  }

  const gameStatusSwitch = (row: Period) => <Switch disabled={row.status !== "ACTIVE"} checked={row.gameStatus === "OPEN"}
                                                    onChange={checked => handleGameStatus(row.id, checked)}/>

  return <div>
    <Table dataSource={periods} pagination={false} rowKey={r => r.id}>
      <Column title="Hafta" key="name" render={r => <Link to={`/periods/${r.id}`}>{r.name}</Link>}/>
      <Column title="Başlangıç" key="startsAt" dataIndex="startsAt" render={formatDay}/>
      <Column title="Bitiş" key="endsAt" dataIndex="endsAt" render={formatDay}/>
      <Column title="Aktif mi?" key="status"
              render={row => <Switch checked={row.status === "ACTIVE"}
                                     onChange={checked => handleStatus(row.id, checked)}/>}/>

      <Column title="Oyun açık mı?" key="gameStatus" render={gameStatusSwitch}/>
      <Column title="Kurulan Takımlar" key="gameStatus" render={row => <Link to={`/lineups/period/${row.id}`}>Listele</Link>}/>
    </Table>
    <div style={{fontStyle: "italic"}}>* Maçlar ENETPLUS'tan verilen tarih aralığına göre çekilir</div>
    <div style={{marginTop: 50}}>
      <Form layout={"inline"} onFinish={handleCreatePeriod}>
        <Form.Item name="name" label="Yeni Hafta"><Input/></Form.Item>
        <Form.Item name="startDate"><DatePicker/></Form.Item>
        <Form.Item name="endDate"><DatePicker/></Form.Item>
        <Form.Item>
          <Button htmlType="submit">Ekle</Button>
        </Form.Item>
      </Form>
    </div>
  </div>
}

export default PeriodList;