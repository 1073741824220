import {Menu} from "antd";
import {Link} from "react-router-dom";
import {PoweroffOutlined} from "@ant-design/icons";
import React from "react";
import {Layout} from "antd";
import {AuthState} from "../../reducers/auth";
import {useSelector} from "../../store";

const { Header } = Layout;

const AppHeader = () => {

  const auth: AuthState = useSelector(state => state.auth)

  const adminMenu = [
    {id: "periods", url: "/periods", label: "Haftalar" },
    {id: "players", url: "/players", label: "Oyuncular" }
  ];

  const renderMenuItem = (m: any) => <Menu.Item key={m.id}><Link to={m.url}>{m.label}</Link></Menu.Item>

  return <Header className={`layout-header-role-` + auth.role.toLowerCase()}>
    <div className="layout-logo">VOLE FS</div>
    <Menu theme="dark" mode="horizontal">
      {
        auth.role === 'ADMIN' &&
        adminMenu.map(m => renderMenuItem(m))
      }
      <Menu.Item className="layout-menu-logout" icon={<PoweroffOutlined />} key="logout">
        <Link to="/auth/logout">Çıkış</Link>
      </Menu.Item>
    </Menu>
  </Header>
}

export default AppHeader;