export type ProgressState = "idle" | "loading" | "succeeded" | "failed"  | "invalidated"

export interface Player {
  id: number
  teamId: number
  name: string
  value: number
  position: string
  photoUrl: string
  alternativeName: string
}

export interface Team {
  id: number
  name: string
}

export interface Period {
  id: number
  name: string
  startsAt: string
  endsAt: string
  status: string
  gameStatus: string
}

export type PlayerPosition = "COACH" | "KEEPER" | "DEFENDER" | "MIDFIELDER" | "FORWARD";
export const PositionMap: MapOf<string> = {
  "COACH": "Teknik Direktör",
  "KEEPER": "Kaleci",
  "DEFENDER": "Defans",
  "MIDFIELDER": "Orta saha",
  "FORWARD": "Forvet",
}

export type MapOf<T> = { [id: string]: T };

export type NumMapOf<T> = { [id: number]: T };

export interface UserLineup {
  id: number
  user: string
  budget: number
  totalValue: number
}

export interface UserLineupDetail {
  id: number
  periodId: number
  user: string
  budget: number
  totalValue: number
  players: UserLineupPlayer[]
}

export interface UserLineupPlayer {
  lineupPosition: PlayerPosition
  lineupGroup: string
  rank: number
  value: number
  stats?: MapOf<number>
  score: number
  player: Player
}