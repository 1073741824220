import React, {useEffect} from "react";
import {Table} from "antd";
import {Link} from "react-router-dom";
import {useAppDispatch, useSelector} from "../../store";
import Loading from "../../components/Loading";
import {getPlayersAction, getTeamsAction} from "../../reducers/player";
import {Player, PositionMap} from "../../reducers/types";
import {sortArray} from "../../services/Utils";

const {Column} = Table;

const PeriodList = () => {
  const playersListStatus = useSelector(s => s.player.listStatus);
  const teamListStatus = useSelector(s => s.player.getTeams);
  const players = useSelector(s => s.player.players);
  const teams = useSelector(s => s.player.teams);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPlayersAction({limit: 20}));
    dispatch(getTeamsAction());
  }, []);

  if (playersListStatus !== "succeeded" || teamListStatus !== "succeeded") return <Loading/>;

  const teamFilters = sortArray(Object.values(teams), "name").map(t => ({text: t.name, value: t.id}));
  const positionFilters = Object.keys(PositionMap).map(t => ({text: PositionMap[t], value: t}));

  const positionMapper = (pos: string) => PositionMap[pos];

  return <div>
    <Table dataSource={Object.values(players)} pagination={{pageSize: 30}} rowKey={r => r.id} size="small">
      <Column title="Takım" key="team" render={p => teams[p.teamId].name} filters={teamFilters} onFilter={(v, r: Player) => r.teamId === v}/>
      <Column title="İsim" key="name" render={row => <Link to={`/players/${row.id}`}>{row.name}</Link>}/>
      <Column title="Pozisyon" key="position" dataIndex="position" filters={positionFilters} onFilter={(v, r: Player) => r.position === v} render={positionMapper}/>
      <Column title="Alt.İsim" key="startsAt" dataIndex="alternativeName" />
    </Table>
  </div>
}

export default PeriodList;