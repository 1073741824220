import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../store";
import api, {QSParams} from "services/ApiService";
import {ProgressState, Player, Team, NumMapOf} from "./types";

export interface PlayersState {
  getTeams: ProgressState
  listStatus: ProgressState
  getPlayerStatus: ProgressState
  savePlayerStatus: ProgressState
  players: NumMapOf<Player>
  teams: NumMapOf<Team>
  total: number
  offset: number
  limit: number
}

const initialState: PlayersState = {
  getTeams: "idle",
  listStatus: "idle",
  getPlayerStatus: "idle",
  savePlayerStatus: "idle",
  players: {},
  teams: {},
  total: 0,
  offset: 0,
  limit: 10,
}

const reducer = createSlice({
  name: 'players',
  initialState,
  reducers: {
    getPlayersInit(state) {
      state.listStatus = "loading"
    },
    getPlayersSuccess(state, action: PayloadAction<Player[]>) {
      const players: NumMapOf<Player> = {};
      action.payload.forEach(p => players[p.id] = p)
      state.players = players;
      state.listStatus = "succeeded"
    },
    getPlayersError(state) {
      state.listStatus = "failed"
    },
    getPlayerInit(state) {
      state.getPlayerStatus = "loading"
    },
    getPlayerSuccess(state, action: PayloadAction<Player>) {
      state.players[action.payload.id] = action.payload;
      state.getPlayerStatus = "succeeded"
    },
    getPlayerError(state) {
      state.getPlayerStatus = "failed"
    },
    savePlayerInit(state) {
      state.savePlayerStatus = "loading"
    },
    savePlayerSuccess(state, action: PayloadAction<Player>) {
      state.players[action.payload.id] = action.payload;
      state.savePlayerStatus = "succeeded"
    },
    savePlayerError(state) {
      state.savePlayerStatus = "failed"
    },
    getTeamsInit(state) {
      state.getTeams = "loading"
    },
    getTeamsSuccess(state, action: PayloadAction<Team[]>) {
      const teams: NumMapOf<Team> = {};
      action.payload.forEach(p => teams[p.id] = p)
      state.teams = teams;
      state.getTeams = "succeeded"
    },
    getTeamsError(state) {
      state.getTeams = "failed"
    },
    savePlayerPhotoSuccess(state, action) {
      state.players[action.payload.id].photoUrl = `${action.payload.url}?rand=${Math.random()}`;
    }
  }
});

const {
  getPlayersInit,
  getPlayersSuccess,
  getPlayersError,
  getPlayerInit,
  getPlayerSuccess,
  getPlayerError,
  savePlayerInit,
  savePlayerSuccess,
  savePlayerError,
  getTeamsInit,
  getTeamsSuccess,
  getTeamsError,
  savePlayerPhotoSuccess,
} = reducer.actions;

export interface FetchPlayersParams extends QSParams {
  teamId?: number
  limit?: number
  total?: number
  offset?: number
}

export const getPlayersAction = (params?: FetchPlayersParams) => (dispatch: AppDispatch) => {
  dispatch(getPlayersInit());
  api.get('/admin/players', params)
    .then(res => {
      dispatch(getPlayersSuccess(res))
    })
    .catch(e => {
      console.error(e);
      dispatch(getPlayersError())
    })
}

export const getTeamsAction = (params?: FetchPlayersParams) => (dispatch: AppDispatch) => {
  dispatch(getTeamsInit());
  api.get('/admin/teams', params)
    .then(res => {
      dispatch(getTeamsSuccess(res))
    })
    .catch(() => dispatch(getTeamsError()))
}

export const getPlayerAction = (id: number) => (dispatch: AppDispatch) => {
  dispatch(getPlayerInit());
  api.get(`/admin/players/${id}`)
    .then(res => {
      dispatch(getPlayerSuccess(res))
    })
    .catch(() => dispatch(getPlayerError()))
}

export const savePlayerAction = (id: number, data: any) => (dispatch: AppDispatch) => {
  dispatch(savePlayerInit());
  api.post(`/admin/players/${id}`, data)
    .then(res => {
      dispatch(savePlayerSuccess(res))
    })
    .catch(() => dispatch(savePlayerError()))
}

export const uploadPhotoAction = (id: number, photo: File) => (dispatch: AppDispatch) => {
  api.postFile(`/admin/players/${id}/photos`, photo)
    .then(res => {
      dispatch(savePlayerPhotoSuccess({id, url: res.url}))
    })
}

export default reducer;