import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../store";
import api, {QSParams} from "services/ApiService";
import {ProgressState, Period, NumMapOf} from "./types";

export interface PeriodState {
  periodGetStatus: ProgressState
  periodsGetStatus: ProgressState
  savePeriodStatus: ProgressState
  periods: Period[]
}

const initialState: PeriodState = {
  periodGetStatus: "idle",
  periodsGetStatus: "idle",
  savePeriodStatus: "idle",
  periods: []
}

const reducer = createSlice({
  name: 'period',
  initialState,
  reducers: {
    getPeriodsInit(state) {
      state.periodsGetStatus = "loading"
    },
    getPeriodsSuccess(state, action: PayloadAction<Period[]>) {
      state.periods = action.payload;
      state.periodsGetStatus = "succeeded"
    },
    getPeriodsError(state) {
      state.periodsGetStatus = "failed"
    },
    getPeriodInit(state) {
      state.periodGetStatus = "loading"
    },
    getPeriodSuccess(state, action: PayloadAction<Period>) {
      state.periods[action.payload.id] = action.payload;
      state.periodGetStatus = "succeeded"
    },
    getPeriodError(state) {
      state.periodGetStatus = "failed"
    },
    savePeriodSuccess(state, action: PayloadAction<Period>) {
      state.periods[action.payload.id] = action.payload;
      state.savePeriodStatus = "succeeded"
    },
    savePeriodInit(state) {
      state.savePeriodStatus = "succeeded"
    },
    savePeriodError(state, action: PayloadAction<string>) {
      state.savePeriodStatus = "failed"
    },
  }
});

const {
  getPeriodsInit,
  getPeriodsSuccess,
  getPeriodsError,
  getPeriodInit,
  getPeriodSuccess,
  getPeriodError,
  savePeriodInit,
  savePeriodSuccess,
  savePeriodError,
} = reducer.actions;

export const getPeriodsAction = () => (dispatch: AppDispatch) => {
  dispatch(getPeriodsInit());
  api.get('/admin/periods')
    .then(res => {
      dispatch(getPeriodsSuccess(res))
    })
    .catch(() => dispatch(getPeriodsError()))
}

export const getPeriodAction = (id: number) => (dispatch: AppDispatch) => {
  dispatch(getPeriodInit());
  api.get(`/admin/periods/${id}`)
    .then(res => {
      dispatch(getPeriodSuccess(res))
    })
    .catch(() => dispatch(getPeriodError()))
}

interface SavePeriodParams {
  id?: number
  data: any
}

export const savePeriodAction = ({id, data}: SavePeriodParams) => (dispatch: AppDispatch) => {
  dispatch(savePeriodInit());
  const url = id ? `/admin/periods/${id}` : '/admin/periods';
  api.post(url, data)
    .then(res => {
      dispatch(savePeriodSuccess(res))
    })
    .catch(() => dispatch(savePeriodError("period_time_conflict")))
}

export default reducer;