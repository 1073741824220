import React, {useEffect} from "react";
import {Button, Form, Input, Upload} from "antd";
import ImgCrop from 'antd-img-crop';
import {useParams} from "react-router-dom";
import {useAppDispatch, useSelector} from "../../store";
import {getPlayerAction, savePlayerAction, uploadPhotoAction} from "../../reducers/player";
import Loading from "../../components/Loading";

const PlayerDetail = () => {
  const {id} = useParams<{ id: string }>();
  const idInt = parseInt(id);
  const player = useSelector(s => s.player.players[idInt])
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPlayerAction(idInt));
  }, []);

  if (!player) return <Loading/>;

  const handleSave = (values: any) => {
    const data = {
      alternativeName: values.alternativeName,
      value: parseInt(values.value),
    }
    dispatch(savePlayerAction(idInt, data));
  }

  const loadFiles = (options: any) => {
    dispatch(uploadPhotoAction(player.id, options.file));
  }

  const initialValues = {
    name: player.name,
    value: player.value,
    alternativeName: player.alternativeName,
  };

  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
  };
  const tailLayout = {
    wrapperCol: {offset: 4, span: 20},
  };

  return <div>
    <Form initialValues={initialValues} onFinish={handleSave} {...layout}>
      <Form.Item label="Fotoğraf">
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
          <img alt="" src={player.photoUrl} width={360}/>
          <ImgCrop modalTitle="Kes" aspect={579/800}>
            <Upload customRequest={loadFiles} showUploadList={false}>
              <Button>Resim Yükle</Button>
            </Upload>
          </ImgCrop>
        </div>
      </Form.Item>
      <Form.Item name="alternativeName" label="Alternatif İsim">
        <Input/>
      </Form.Item>
      <Form.Item name="value" label="Değeri">
        <Input/>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">Kaydet</Button>
      </Form.Item>
    </Form>
  </div>
}

export default PlayerDetail;