import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { Redirect } from 'react-router'
import {RootState} from "store";
import { loginAction } from 'reducers/auth'
import LoginForm, {LoginFormValues} from './LoginForm'
import {Card, Alert} from "antd";

const Index = () => {
  const isAuth = useSelector<RootState>(state => state.auth.isAuth);
  const dispatch = useDispatch();
  const loginStatus = useSelector<RootState>(state => state.auth.loginStatus);

  const onSubmit = (values: LoginFormValues): void => {
    dispatch(loginAction(values.username ?? '', values.password ?? ''))
  };

  if (isAuth) return <Redirect to="/" />

  return <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Card style={{width: 300}}>
      {
        loginStatus === 'failed' &&
          <Alert type="error" message="Invalid credentials" style={{marginBottom: 20}}/>
      }
      <LoginForm onSubmit={onSubmit} loading={loginStatus === 'loading'} />
    </Card>
  </div>
};

export default Index;